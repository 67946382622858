<template>
  <div v-if="showTimer && remainingTime > 0" class="countdown">
    <span class="long">Closing</span>
    <span class="short">Closes</span>
    in {{ remainingTime }}
    <span class="long">seconds</span>
    <span class="short">s</span>
  </div>
</template>

<script setup>
const log = (message) => console.log(message); // eslint-disable-line
const props = defineProps(["timeout", "callback"]);
import {ref, computed, onMounted, onUnmounted} from "vue";
const remainingTime = ref(props.timeout);
let timer = null;

// Show timer only if timeout is valid and greater than zero
const showTimer = computed(() => {
  return props.timeout !== undefined && props.timeout > 0;
});

const startTimer = () => {
  if (!showTimer.value) return;
  timer = setInterval(() => {
    if (remainingTime.value > 1) {
      remainingTime.value--;
    } else {
      clearInterval(timer);
      remainingTime.value = 0; // Ensure no negative value is shown
      if (props.callback) props.callback(); // Notify parent when timer reaches zero
    }
  }, 1000);
};

log(`Timer: timeout = ${props.timeout}`);

onMounted(() => startTimer());
onUnmounted(() => clearInterval(timer));
</script>

<style scoped>
.countdown {
  /* font-size: var(--small); */
  color: lightgray;
  /* background-color: rgba(0, 0, 0, 0.7); */
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
}
span.long {
  display: inline;
}
span.short {
  display: none;
}
:is(.narrow, .medium) span.long {
  display: none !important;
}
:is(.narrow, .medium) span.short {
  display: inline;
}
</style>
