// useBackground.js

const log = (message) => console.log(message); // eslint-disable-line

import history from "@/assets/history.json";
let imageURL; // initially, background image

import {ref} from "vue";
const callToActionStyle = ref({
  height: "0px",
  backgroundColor: "#000000" // Default call-to-action color (off-black)
});
export function setCallToActionStyle(newHeight, newBackgroundColor) {
  log(`useBackground: CTA [height, backgroundColor] = [${newHeight}, ${newBackgroundColor}]`);
  callToActionStyle.value.height = newHeight;
  callToActionStyle.value.backgroundColor = newBackgroundColor;
  applyGradient(imageURL);
}
function callToActionBanner() {
  let backgroundColor = `${callToActionStyle.value.backgroundColor}`;
  let height = `${callToActionStyle.value.height}`;
  return `${backgroundColor} 0%, ${backgroundColor} ${height}`;
}

function gradient(imageUrl) {
  const style = getComputedStyle(document.body); // get css variable
  const end = style.getPropertyValue("--background-color");
  let start;
  if (imageUrl) {
    log(`useBackground: image URL = ${imageUrl}`);
    start = "rgba(0, 0, 0, 0)"; // must be fully transparent
    imageUrl = `, url(${imageUrl})`;
  } else {
    //log(`useBackground: No background image`);
    start = style.getPropertyValue("--top-of-page"); // color if no image
    imageUrl = "";
  }
  const banner = callToActionBanner();
  const height = callToActionStyle.value.height;
  //const gradient = `to bottom, ${banner}, ${start} ${height}, ${end} 600px`;
  const gradient = `${start} ${height}, ${end} 500px`;
  let output;
  if (callToActionStyle.value.height === "0px") {
    log(`useBackground: remove CTA banner from background`);
    output = `linear-gradient(${gradient})${imageUrl}`;
  } else {
    log(`useBackground: add CTA banner to background`);
    output = `linear-gradient(${banner}, ${gradient})${imageUrl}`;
  }
  log(`useBackground: gradient = ${output}`);
  return output;
}

function gradientNoImage() {
  document.body.style.backgroundImage = gradient("");
}

function applyGradient(imageURL) {
  log(`useBackground: applyGradient(${imageURL})`);
  if (!imageURL) {
    gradientNoImage();
    return; // early exit
  }
  const testImage = new Image();
  const height = callToActionStyle.value.height;
  testImage.onload = () => {
    // Image exists and is loaded, apply the background style
    const style = getComputedStyle(document.body); // get css variable
    const end = style.getPropertyValue("--background-color");
    document.body.style.backgroundColor = end;
    document.body.style.backgroundImage = gradient(imageURL);
    //document.body.style.backgroundRepeat = "repeat-x no-repeat"; //no-repeat
    document.body.style.backgroundRepeat = "no-repeat, repeat-x"; //no-repeat
    //document.body.style.backgroundAttachment = "scroll, scroll";
    //document.body.style.margin = "0"; // https://stackoverflow.com/q/48240969
    document.body.style.backgroundPosition = `top, top ${height} left 0`;
    document.body.style.backgroundSize = `cover, contain`;
    //document.body.style.backgroundSize = `contain, 100% 500px`;
    //document.body.style.backgroundSize = `auto, 100% auto`;
    //document.body.style.minHeight = `100vh`; /* Ensures viewport height   */
  };
  testImage.onerror = () => {
    // Image failed to load, apply a fallback style
    console.warn(`Error loading background image: ${imageURL}`);
    gradientNoImage();
  };
  testImage.src = imageURL; // try to load image > trigger onload or onerror
}

export function setBackgroundImage(year) {
  year = year.value || history.latestYear;
  log(`useBackground: year = ${year}`);
  let newImageURL = `/${year}/Background.jpg`;
  if (newImageURL === imageURL) return; // no change needed
  imageURL = newImageURL; // store for next time
  log(`useBackground: imageUrl = ${newImageURL}`);
  applyGradient(imageURL);
}

import {useDisplayMode} from "@/composables/useDisplayMode";
const displayMode = useDisplayMode(); // single value returned from composable
import {watch} from "vue";
// re-appy background gradient when displayMode changes; use stored imageURL
watch(displayMode, () => applyGradient(imageURL));
