// watch for data changes, identifies changed column, and invokes useDataAnimation

import {ref, watch} from "vue";
import {useDataAnimation} from "@/composables/useDataAnimation";

export function useDataTransition(data, duration = 1000, steps = 10) {
  const intermediateData = ref(null); // Reactive data for animations

  let animationCounter = 0; // Count how many times animation is triggered

  watch(
    data,
    (newData, oldData) => {
      if (!newData || !oldData) {
        console.log("Initializing data in useDataTransition.");
        intermediateData.value = {...newData}; // Ensure reactivity
        return;
      }

      const headers = Object.keys(newData);
      const changedColumnIndex = headers.findIndex(
        (header) => JSON.stringify(newData[header]) !== JSON.stringify(oldData[header])
      );

      if (changedColumnIndex !== -1) {
        const columnKey = headers[changedColumnIndex];
        const oldColumn = Object.values(oldData[columnKey] || {});
        const newColumn = Object.values(newData[columnKey] || {});

        console.log("Column Key:", columnKey);
        console.log("Old Column Values:", oldColumn);
        console.log("New Column Values:", newColumn);

        const animatedValues = useDataAnimation(oldColumn, newColumn, duration, steps);

        // Deeply update intermediateData
        animatedValues.value.forEach((value, index) => {
          console.log(`Animated value for row ${index}: ${value}`);
        });

        intermediateData.value = Object.assign({}, newData, {
          [columnKey]: Object.keys(newData[columnKey]).reduce((acc, key, index) => {
            acc[key] = animatedValues.value[index];
            return acc;
          }, {})
        });
        console.log("Updated intermediateData:", intermediateData.value);
      } else {
        console.log("No changes detected.");
        intermediateData.value = {...newData};
      }
    },
    {deep: true}
  );

  return {intermediateData};
}
