<template>
  <div v-if="options" class="tab-slider">
    <div class="slider-container">
      <div class="slider" :style="sliderStyle" />
      <button
        v-for="(option, index) in shortOptions"
        :key="index"
        class="tab-button"
        :class="{active: selectedIndex === index}"
        @click="selectTab(index)">
        {{ option }}
      </button>
    </div>
  </div>
</template>

<script setup>
import {ref, computed, defineProps, watch, inject, onMounted} from "vue";
import {useStorage} from "@vueuse/core"; // Simplify state persistence
import {useShort} from "@/composables/useShort";

const log = (message) => console.log(message); // eslint-disable-line
const props = defineProps(["options", "selectedOption", "abbreviations"]);
const displayWidth = inject("displayWidth"); // from composable/display.js
const emit = defineEmits(["update:selectedOption"]);
// UseStorage for state persistence
const selectedIndex = useStorage("slider-selected-index", 0); // Persist state in localStorage

// Computed property for slider style
const sliderStyle = computed(() => {
  const width = `${100 / props.options.length}%`;
  const transform = `translateX(${selectedIndex.value * 100}%)`;
  return {width, transform};
});

// Update selected index and emit change with shortened value
const selectTab = (index) => {
  selectedIndex.value = index;
  emit("update:selectedOption", props.options[index]);
};

const {short} = useShort(props.abbreviations);
// Create `longOptions` array via reverse lookup
const longOptions = computed(() => {
  return props.options.map((shortOption) => {
    const match = Object.entries(props.abbreviations).find(
      ([long, short]) => short === shortOption
    );
    return match ? match[0] : shortOption; // Use the long version if found, else fallback
  });
});
// Create `shortOptions` based on display width
const shortOptions = computed(() => {
  const width = displayWidth.value;
  const output = longOptions.value.map((option) => short(option));
  log(`Slider: output = ${JSON.stringify(output)}`);
  return output;
});

onMounted(() => {
  if (selectedIndex.value >= props.options.length) {
    // Reset to a valid index if the stored value is invalid
    selectedIndex.value = 0;
  }
  emit("update:selectedOption", props.options[selectedIndex.value]); // Sync with parent
});

// Sync selectedIndex with selectedOption prop
watch(
  () => props.selectedOption,
  (newValue) => {
    const index = props.options.indexOf(newValue);
    //selectedIndex.value = index >= 0 ? index : 0; // Default to the first item
  },
  {immediate: true}
);
</script>

<style scoped>
.tab-slider {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 16px;
  min-height: auto;
}

.slider-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
  position: relative;
  width: 100%;
  max-width: 100%; /* Allow full expansion */
  background-color: #f0f0f0;
  border-radius: 16px;
  overflow: hidden;
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #0a8;
  border-radius: 16px;
  transition: transform 0.3s ease, width 0.3s ease;
  z-index: 1;
}

.tab-button {
  all: unset;
  z-index: 2;
  background: none !important;
  border: none !important;
  padding: 8px 16px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  transition: color 0.3s ease;
}

.tab-button.active {
  color: white;
}

.tab-button:not(.active) {
  color: #555;
}
</style>
