<!-- Copyright: Seán I. O'Donoghue -->
<!-- TODO: refactor to SecondaryItem.vue -->

<template>
  <nav v-if="$route.params.year" id="NavBar" :class="{hidden: !showNavBar}">
    <!-- creates list of pages for the selected year with .vue files -->
    <router-link
      v-for="page in Object.keys(currentYearPages)"
      :key="page"
      :to="'/' + $route.params.year + '/' + page.replace('About', '')"
      :class="{page: true, selected: currentYearPages[page].selected}">
      {{ page.replace("About", $route.params.year) }}
    </router-link>
  </nav>
</template>

<script>
//const About = () => import(`@/assets/${this.$route.params.year}/About.vue`);
//this.log(`Found @/assets/${this.$route.params.year}/About.vue`);
import stringify from "json-stringify-safe";
export default {
  name: "NavigationSecondary",
  data() {
    return {
      allPossiblePages: [
        // 'About' changes to selected year (e.g., 2022) and links to /2022/
        "About",
        "People",
        "Program",
        "Posters",
        "Photos",
        "Masterclass",
        "VR",
        "Workshop",
        "Showcase",
        "Registration", // can have 'Form' subpage
        "Venue",
        "Platform",
        "Sponsors",
        "Contact"
      ],
      currentYearPages: {},
      lastScrollPosition: 0,
      showNavBar: true
    };
  },
  watch: {
    $route: function () {
      if (!this.$route.params.year) {
        if (this.log) {
          this.log("No year in route: SecondaryNavigation should bug out");
        }
      } else {
        // Otherwise, update whenever route changes
        this.currentYearPages = this.pageCache(this.$route.params.year);
        this.updateSelectionState();
      }
    }
    // listen for prop changes https://stackoverflow.com/q/44584292
  },
  mounted() {
    this.log("NavigationSecondary mounted()");
    this.currentYearPages = this.pageCache(this.$route.params.year); // When page first loaded, update list
    this.updateSelectionState(); // Update selection state
    window.addEventListener("scroll", this.onScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    // eslint-disable-next-line
    log(message) {
      if (process.env.NODE_ENV === "development") {
        //console.log(message); // uncomment to show logs from this component
      }
    },
    pageCache: (function () {
      //this.log(`Cache outer function called`);
      let cachedPages = {};
      // Inner Public function / method with remembered values
      return function (year) {
        //this.log(`Cache inner public function called for ${year}`);
        if (!year) {
          return;
        }
        if (!cachedPages[year]) {
          //this.log(`Cache set for ${year}`);
          cachedPages[year] = this.updatePageList(year);
        }
        return cachedPages[year];
      };
    })(),
    pingFile(page) {
      // Special handling for the year 'About' page
      if (page === "About") {
        page = "Index.vue";
      } else {
        page = page + "/Index.vue";
      }
      try {
        // Test if a vue file exists for this page
        let indexPage = require(`@/assets/years/${this.$route.params.year}/${page}`);
        this.log(`Found @/assets/years/${this.$route.params.year}/${page}`);
        this.log(`indexPage = ${stringify(indexPage)}`);
        if (window.location.host === "vizbi.org") {
          // Do the following test only on the production version (vizbi.org)
          if (indexPage.default.props && indexPage.default.props.devOnly) {
            // for pages with the 'devOnly' prop, ...
            this.log(`devOnly = ${indexPage.default.props.devOnly}`);
            return false; //don't publish this page on vizbi.org
          }
        }
        return true; // otherwise, publish this page
      } catch (error) {
        this.log(`Did not find @/assets/years/${this.$route.params.year}/${page}`);
        return false;
      }
    },
    updatePageList(year) {
      if (!this.pingFile("About")) {
        // Bug out if there is no index.vue file for the selected year
        return false;
      }
      try {
        // for currently selected year, find which pages have .html files
        this.log(`Checking pages for ${year}`);
        let currentYearPages = this.allPossiblePages
          .filter((page) => this.pingFile(page))
          .reduce((a, v) => ({...a, [v]: {selected: false}}), {});
        // https://stackoverflow.com/q/4215737
        this.log(`Found vue files for: ${Object.keys(currentYearPages).toString()}`);
        return currentYearPages;
      } catch (error) {
        console.error(`Did not find file: ${error}`);
      }
    },
    updateSelectionState() {
      for (var page of Object.keys(this.currentYearPages)) {
        this.log(`page: ${page}, name: ${this.$route.name}, year: ${this.$route.params.year}`);
        if (page === "About" && this.$route.name === "AboutYear") {
          this.currentYearPages[page].selected = true;
          this.log(`Selected page: ${page}`);
        } else if (page === "Posters" && this.$route.name === "Poster") {
          // exception for Posters: route name will be 'Poster'
          this.currentYearPages[page].selected = true;
          this.log(`Selected page: ${page}`);
        } else if (page === this.$route.name) {
          // one of the main page selected
          this.currentYearPages[page].selected = true;
          this.log(`Selected page: ${page}`);
        } else {
          // disable selection for all other 'pages' in the list
          this.currentYearPages[page].selected = false;
        }
      }
    },
    onScroll() {
      // https://medium.com/@Taha_Shashtari/hide-navbar-on-scroll-down-in-vue-fb85acbdddfe
      // Get the current scroll position
      //this.log(`onScroll()`);
      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop; // Because of momentum scrolling on mobiles, we shouldn't continue if it is less than zero
      if (currentScrollPosition < 0) {
        return;
      }
      if (currentScrollPosition < 250) {
        // always show menu when close to the top of the page
        this.showNavBar = true;
        return;
      }
      if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 50) {
        // Stop executing this function if the difference between current
        // scroll position and last scroll position is less than some offset
        return;
      }
      //this.log(`currentScrollPosition = ${currentScrollPosition}`);
      this.showNavBar = currentScrollPosition < this.lastScrollPosition;
      this.lastScrollPosition = currentScrollPosition;
    }
  }
};
</script>

<style scoped>
/* CSS limited to this component only */
nav {
  letter-spacing: 0.1px;
  font-weight: 700;
  /* padding: 8px 8px 8px 8px; */
  width: 100%;
  box-sizing: border-box;
  /*overflow: auto; /* prevents main content from wrapping with year */
  position: sticky;
  top: 0px;
  z-index: 10;
  transform: translate3d(0, 0, 0);
  transition: 0.3s all ease-out;
  background-color: var(--background-color);
}
nav.hidden {
  /* box-shadow: none; */
  transform: translate3d(0, -100%, 0);
}
h2 {
  float: left;
  margin-top: 0px;
  margin-bottom: 8px;
  box-sizing: border-box;

  /* padding-bottom: 30px; */
}
h2 a {
  box-sizing: border-box;
  font-size: 44px;
  line-height: 30px;
}
nav {
  margin-top: 0px;
  margin-bottom: 20px;
  padding: 0px;
}
a {
  /* border-left: 1px solid #ccc; */
  display: inline-block;
  margin-bottom: 4px;
}
a:first-child {
  /* the first element - '2022' etc */
  /* https://www.w3schools.com/cssref/sel_firstchild.asp */
  float: left;
  border-right: 1px solid var(--text-color-subtle);
  /* border: none; */
  padding-left: 0px;
  margin-left: 0px;
  margin-right: 0px;
}
a {
  color: var(--text-color-subtle); /* c0c0c0*/
  /* text-transform: uppercase; */
  text-decoration: none;
}
a:hover {
  cursor: pointer;
  color: var(--text-color-less-subtle);
}
a {
  vertical-align: top;
}
/* sets the continent color on selection in the navigation list */
a.selected {
  color: var(--brand-color);
}
a.selected:hover {
  color: var(--brand-hover-color);
}
/* Mobile first - narrow */
.narrow a {
  font-size: 12px; /* was: 0.8rem 18px */
  line-height: 14px; /* was: 1rem  */
}
.narrow a:first-child {
  font-size: 16px; /*was: 2.5rem 44px */
  line-height: 32px; /* was: 1.7rem 30px */
  padding-right: 6px;
}
/* medium */
.medium a {
  font-size: 15px; /* was: 0.8rem 18px */
  line-height: 21px; /* was: 1rem  */
}
.medium a:first-child {
  font-size: 25px; /* was: 2.5rem 44px */
  line-height: 50px; /* was: 1.7rem 30px */
  padding-right: 8px;
}
/* wide */
.wide a {
  font-size: 17px; /* was: 0.8rem 18px */
  line-height: 22px; /* was: 1rem  */
}
.wide a:first-child {
  font-size: 30px; /* was: 2.5rem 44px */
  line-height: 30px; /* was: 1.7rem 30px */
  padding-right: 11px;
}
/* unlimited */
.unlimited a {
  font-size: 18px; /* was: 0.8rem 18px */
  line-height: 24px; /* was: 1rem  */
}
.unlimited a:first-child {
  font-size: 34px; /* was: 2.5rem 44px */
  line-height: 34px; /* was: 1.7rem 30px */
  padding-right: 12px;
}
.narrow nav,
.narrow nav {
  margin-bottom: 6px;
}
a + a {
  margin-left: 1em;
}
</style>
