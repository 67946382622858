<!-- Copyright: Seán I. O'Donoghue -->

<template>
  <h1>
    VISUALIZING BIOLOGICAL DATA
    <span id="logo">
      <!-- tablindex - remove this link from the tab index -->
      <router-link to="/About" tabindex="-1">VIZBI</router-link>
    </span>
  </h1>
</template>

<script>
export default {
  name: "Logo"
};
</script>

<style scoped>
/* scoped = CSS limit to this component only */
/* mobile first: medium and .narrow */
h1 {
  /* hide burger bars on wide screen devices */
  display: inline;
  color: var(--title-color);
  font-weight: 600;
  font-size: 3.7vw; /* 0.9em */
  margin: 0;
  padding: 0;
  text-wrap: balance; /* works on Chrome */
  vertical-align: baseline;
}
:is(.wide h1, .medium h1) {
  display: block;
  /* font-size: clamp(2rem, 7vw, 3rem); min, preferred, max was: 3.12em; */
  font-size: clamp(1.8rem, 6.5vw, 3rem); /* Reduced scaling for smaller screens */
  line-height: 90%; /* 90% of the font-size */
}
/* tested using:
const minSize = 2 * 16; // 2rem assuming font size is 16 pixels
const maxSize = Math.min(3 * 16, 7 * window.innerWidth / 100); // 3rem or 7vw (whichever is smaller)

const value = `${maxSize}px`;
console.log(value); */

.unlimited h1 {
  display: block;
  font-size: 3rem;
  line-height: 90%; /* 90% of the font-size */
}
span#logo {
  font-weight: 800;
  text-shadow: 0 0 0.5rem #333;
}
a {
  color: var(--brand-color);
  text-decoration: none;
}
a:hover,
a.selected {
  color: var(--brand-hover-color);
}
</style>
