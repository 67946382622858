<template>
  <div id="banner" ref="banner" :class="{hideForm: route.name === 'Form'}">
    <span class="left-text">
      <span id="lineBreak">
        Cambridge
        <span v-if="displayWidth === 'unlimited' && route.name !== 'Registration'"> UK</span>
      </span>
      and online
    </span>
    <div class="center-content">
      <RegisterNow v-if="history?.registrationOpen" />
      <PreRegisterNow v-else-if="history?.preRegistrationOpen" />
      <StayUpdated v-else />
    </div>
    <span class="right-text">April<br />8–11</span>
  </div>
</template>

<script setup>
const log = (message) => console.log(message); // eslint-disable-line
import history from "@/assets/history.json";
import {inject, nextTick, onMounted, ref, watch} from "vue";
import {useRoute} from "vue-router";
import PreRegisterNow from "@/components/CallToAction/PreRegisterNow";
import RegisterNow from "@/components/CallToAction/RegisterNow.vue";
import StayUpdated from "@/components/CallToAction/StayUpdated.vue";
import {setCallToActionStyle} from "@/composables/useBackground"; // fit to call-to-action
const displayWidth = inject("displayWidth"); // from App.vue > composable/
const banner = ref(null);
const route = useRoute(); // access the current route object
let height = "80px"; // initially, set to default for narrow displays
function setBannerHeight() {
  if (!banner.value) return console.error("Banner element is not available");
  if (displayWidth.value === "narrow") {
    height = "70px";
  } else if (displayWidth.value === "medium") {
    height = "90px";
  } else if (displayWidth.value === "wide") {
    height = "95px";
  } else if (displayWidth.value === "unlimited") {
    height = "100px";
  }
  log(`Banner: displayWidth = ${displayWidth.value}`);
  log(`Banner: height = ${height}`);
  banner.value.style.height = height; // Set desired height for <div></div>
  // Use nextTick to apply styles after Vue has completed the DOM update
  nextTick(() => {
    if (!banner?.value) return;
    banner.value.style.height = height;
    setCallToActionStyle(height, "black"); // Adjust <body>
  });
}
// Set div's height once component is mounted
onMounted(() => {
  if (banner.value) setBannerHeight(height);
});
// hide CTA on the Registration/Form page:
watch(
  () => route.name,
  (newRouteName, oldRouteName) => {
    log(`Banner: [newRouteName, oldRouteName] = ${newRouteName}, ${oldRouteName}]`);
    if (newRouteName === "Form") {
      log(`Banner: Hide CTA `);
      setCallToActionStyle("0px", "#000");
    } else if (oldRouteName === "Form") {
      log(`Banner: Reveal CTA`);
      setBannerHeight();
    }
  }
);
// resize CTA with displayWidth changes
watch(displayWidth, (newDisplayWidth, oldisplayWidth) => {
  log(`Banner: [newDisplayWidth, oldisplayWidth] = ${newDisplayWidth}, ${oldisplayWidth}]`);
  if (route.name === "Form") return; // no change
  if (newDisplayWidth === oldisplayWidth) return; // no change
  setBannerHeight();
});
</script>

<style scoped>
#banner {
  display: flex;
  position: relative; /* Ensure absolute positioning is relative to this element */
  justify-content: space-between; /* Keeps left and right text at the sides */
  align-items: center;
  color: white;
  font-size: 0.99rem;
  padding: 0 1.05rem 0 0.75rem;
  line-height: 1.05;
}
:is(.wide #banner, .medium #banner) {
  font-size: clamp(1.2rem, 4vw, 1.73rem); /* min, preferred, max was: 3.12em; */
}
.unlimited #banner {
  font-size: 1.73rem;
  height: 100px;
}
.left-text {
  margin-right: auto;
}
.right-text {
  margin-left: auto;
}
.left-text span {
  display: inline;
}
.center-content {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
#lineBreak {
  display: block; /* Forces a new line */
}
#lineBreak span {
  display: inline; /* prevent above new line */
}
.hideForm {
  display: none; /* Or 'visibility: hidden;' if you want to keep the space */
}
</style>
