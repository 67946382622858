<template>
  <div>
    <!-- begin image src with "./" so it's imported as a vue 'static asset' -->
    <!-- https://cli.vuejs.org/guide/html-and-static-assets.html#static-assets-handling -->
    <a :href="url">
      <img
        src="./img/Magdalene.svg"
        alt="Magdalene, U. Cambridge"
        title="Magdalene, U. Cambridge" />
    </a>
    <a v-if="displayWidth === 'unlimited'" :href="url">
      <span>Magdalene College,</span><br />
      <span>University of Cambridge</span>
    </a>
    <a v-if="displayWidth.match(/medium|wide/)" :href="url">
      <span>Magdalene</span><br />
      <span>U. Cambridge</span>
    </a>
  </div>
</template>

<script setup>
import {inject} from "vue";
// seems more robust that using the style/CSS below
const displayWidth = inject("displayWidth"); // from composable/display.js
const url = "https://www.magd.cam.ac.uk/conference/conferences-and-meetings";
</script>

<style scoped>
div {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin-top: -0.2rem;
  width: fit-content;
}
.narrow div {
  margin-top: 0rem;
}
a {
  /* for wide display */
  color: var(--primary-navigation-color); /* override default link color */
  text-shadow: rgb(0, 0, 0) 0px 0px 6px;
  font-weight: bold;
  line-height: 1.1;
  /* background-color: red; */
}
.medium a span {
  font-size: 0.8rem;
  line-height: 1;
}

img {
  width: 30px;
  /* background-color: rgba(0, 0, 0, 0.4); */
  padding: 0.15rem;
  filter: drop-shadow(2px 2px 3px rgba(0, 0, 0, 0.9));
}
.medium img {
  margin-top: 0.1rem;
  width: 27px;
}
.narrow img {
  width: 23px;
}

br {
  margin-bottom: 0px;
}
</style>
