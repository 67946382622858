<!-- Copyright: Seán I. O'Donoghue -->

<template>
  <figure>
    <video
      v-if="isVideoPlayable"
      ref="videoPlayer"
      autoplay
      muted
      playsinline
      @error="onVideoError"
      @canplay="onVideoLoaded"
      @mouseover="onMouseOver"
      @click="onClick">
      <source v-if="webm" :src="webm" type="video/webm" />
      <source v-if="mp4" :src="mp4" type="video/mp4" />
    </video>
    <img v-else :src="jpg" alt="Fallback content" />
    <figcaption>
      <small v-if="credit" class="credit">&#169; {{ credit }}</small>
    </figcaption>
  </figure>
</template>

<script setup>
import {ref, onMounted} from "vue";

// Video state variables
const videoPlayer = ref(null);
const isVideoPlaying = ref(false); // Track if the video is currently playing
const isVideoPlayable = ref(true); // Fallback logic
const isVideoLoaded = ref(false);

// Props
const props = defineProps(["webm", "mp4", "jpg", "credit"]);

// Video control methods
const playVideo = () => {
  if (videoPlayer.value && !isVideoPlaying.value) {
    videoPlayer.value.currentTime = 0; // Start from the beginning
    videoPlayer.value.play();
    isVideoPlaying.value = true; // Mark video as playing

    // Reset isVideoPlaying when the video ends
    videoPlayer.value.addEventListener(
      "ended",
      () => {
        isVideoPlaying.value = false; // Video has stopped
      },
      {once: true}
    );
  }
};

// Event Handlers
const onMouseOver = () => {
  if (!isVideoPlaying.value) {
    // Only play if not already playing
    playVideo();
  }
};

const onClick = () => {
  if (!isVideoPlaying.value) {
    // Only play if not already playing
    playVideo();
  }
};

const onVideoError = () => {
  isVideoPlayable.value = false; // Switch to fallback image on error
};

const onVideoLoaded = () => {
  isVideoLoaded.value = true; // Mark video as ready
};

// Mounted lifecycle
onMounted(() => {
  if (!videoPlayer.value) return;

  // Check if video is already loaded
  if (videoPlayer.value.readyState >= 3) {
    onVideoLoaded(); // Video is ready
  } else {
    videoPlayer.value.addEventListener("canplay", onVideoLoaded, {once: true});
  }

  // Initial play (optional, based on requirements)
});
</script>

<style scoped>
figure {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  margin: 0;
}

:is(video, img) {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: auto;
  object-fit: cover;
}

figcaption {
  margin-top: 8px;
  text-align: left;
  font-size: 0.9rem;
  color: #666;
}

.credit {
  display: block;
  margin-bottom: 4px;
  font-size: 0.8rem;
  color: #888;
}
</style>
