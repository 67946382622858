<template>
  <!-- <h4>Primary sponsor</h4>
  <h4>Co-sponsors</h4> -->
  <div>
    <a href="https://www.biologists.com/" rel="nofollow">
      <img
        v-if="displayMode === 'lightMode'"
        src="./img/CoB-lightMode.svg"
        alt="CoB"
        title="The Company of Biologists" />
      <img v-else src="./img/CoB.svg" alt="CoB" title="The Company of Biologists" />
    </a>
    <a href="https://www.frontiersin.org/journals/bioinformatics" rel="nofollow">
      <img
        v-if="displayMode === 'lightMode'"
        id="frontiers"
        src="./img/frontiers-lightMode.svg"
        alt="FBINF"
        title="Frontiers in Bioinformatics" />
      <img
        v-else
        id="frontiers"
        src="./img/frontiers.svg"
        alt="FBINF"
        title="Frontiers in Bioinformatics" />
    </a>
    <a href="https://biovis.net/" rel="nofollow">
      <img
        v-if="displayMode === 'lightMode'"
        class="biovis"
        src="./img/biovis-lightMode.svg"
        alt="BioVis "
        title="BioVis" />
      <img v-else class="biovis" src="./img/biovis.svg" alt="BioVis " title="BioVis" />
    </a>
    <a href="https://vcc.kaust.edu.sa/" rel="nofollow">
      <img id="kaust" src="./img/kaust.png" alt="KAUST VCC" title="KAUST Visual Computing Center" />
    </a>
  </div>
  <h4>Affiliates</h4>
  <div>
    <a href="http://www.iscb.org/" rel="nofollow">
      <img src="./img/ISCB.jpeg" alt="ISCB" title="ISCB" />
    </a>
    <a href="https://www.eg.org/" rel="nofollow">
      <img
        v-if="displayMode === 'lightMode'"
        src="./img/EG-lightMode.png"
        alt="Eurographics"
        title="Eurographics" />
      <img v-else src="./img/EG.png" alt="Eurographics" title="Eurographics" />
    </a>
    <!-- <a href="https://www.datavisualizationsociety.org" rel="nofollow">
      <img
        v-if="displayMode === 'lightMode'"
        src="./img/DVS-lightMode.png"
        alt="Data Visualization Society"
        title="Data Visualization Society" />
      <img v-else src="./img/DVS.png" title="Data Visualization Society" />
    </a> -->
  </div>

  <h4>Become a Sponsor</h4>
  <p>
    Partner with us to shape the future of data visualization! By sponsoring VIZBI 2025, you'll gain
    exclusive access to a targeted audience of leading academics, scientific publishers,
    communicators, and representatives from the major life science industries. Showcase your brand,
    build meaningful connections, and highlight your dedication to driving progress and innovation.
    Let's collaborate to create meaningful impact and lasting relationships.
  </p>

  <p><b>Ready to take the next step?</b></p>
  <p>
    Contact Veronika Hotton at <Email to="veronika" /> or schedule a meeting directly
    <a href="https://hello.showcare.com/meetings/veronika-hotton">here</a>. Let's explore customized
    sponsorship opportunities that align with your goals!
  </p>
  <!--
  <h4>Media Partners</h4>
  <div>
    <a href="http://www.digizyme.com/" rel="nofollow">
      <img src="./img/Digizyme.png" alt="Digizyme" />
    </a>
    </a>
    <a href="https://iubmb.org/">
      <img src="./img/iubmb.jpeg" alt="IUBMB" title="International Union of Biochemistry and Molecular Biology" />
    </a>
    <a href="https://peerj.com/">
      <img src="./img/PeerJ.png" alt="PeerJ" title="PeerJ" />
    </a>
    <a href="http://rsob.royalsocietypublishing.org/">
      <img src="./img/rsob.png" alt="Open Biology" title="Open Biology, a Royal Society journal" class="openBiology" />
    </a>
  </div>
    -->
</template>

<script setup>
const log = (message) => console.log(message);
import Email from "@/components/Email.vue";
import {inject} from "vue";
const displayMode = inject("displayMode");
</script>

<style scoped>
/* Styles in this page are set in views/Sponsors.vue using the deep selector */
/* Some logos need individual adjustments */
/* https://stackoverflow.com/q/3029422 */

img.biovis {
  height: 2.3rem !important;
}
img#frontiers {
  height: 2.3rem !important;
}
.narrow img#frontiers {
  height: 1.7rem !important;
}
img#kaust {
  max-height: 5rem !important;
}
.narrow img#kaust {
  max-height: 3rem !important;
}
</style>
