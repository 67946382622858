<template>
  <dialog ref="dialogRef" class="modal" role="dialog" aria-modal="true">
    <div class="modal-wrapper">
      <div ref="contentRef" class="modal-content" :style="{backgroundColor: props.background}">
        <AppCloseButton class="close" :callback="hideModal" />
        <slot />
        <div class="row">
          <slot name="footer" />
          <Timer :key="timerKey" :timeout="timeout" :callback="hideModal" />
        </div>
      </div>
    </div>
  </dialog>
</template>

<script setup>
const log = (message) => console.log(message);
import {ref, onMounted, watch} from "vue";
import {useRoute, useRouter} from "vue-router";
import {onClickOutside} from "@vueuse/core";
const props = defineProps(["background", "hash", "timeout"]);
const emit = defineEmits(["closed"]);
const [dialogRef, timerKey] = [ref(null), ref(0)];
const [route, router] = [useRoute(), useRouter()];
const contentRef = ref(null);

function showModal() {
  log(`ModalDialog: showModal called`);
  document.body.style.overflow = "hidden"; // Disable scrolling on the body
  const modal = dialogRef.value;
  if (!modal) return console.error("ModalDialog: dialogRef not defined.");
  modal.showModal();
  timerKey.value++;
}
function hideModal() {
  log(`ModalDialog: hideModal called`);
  document.body.style.overflow = ""; // Re-enable scrolling on the body
  const modal = dialogRef.value;
  if (!modal) return log(`ModalDialog: no dialogRef.value`);
  if (!modal.open) return log(`ModalDialog: modal already closed`);
  console.log("ModalDialog: modal is currently open");
  modal.close();
  emit("closed"); // send closed event to parent
  router.replace({path: route.path});
}
function checkHash() {
  log(`ModalDialog: checkHash called with hash = ${route.hash}`);
  if (!props.hash) return log(`ModalDialog: no hash in props`);
  if (!route.hash.endsWith(props.hash)) return hideModal();
  showModal();
}

onMounted(() => {
  const [hash, timeout] = [props.hash, props.timeout];
  log(`ModalDialog: mounted with [hash, timeout] = ['${hash}', ${timeout}]`);
  onClickOutside(contentRef, hideModal);
  checkHash();
});

watch(() => route.hash, checkHash);
</script>

<style scoped>
dialog {
  position: fixed;
  pointer-events: auto;
  border: none;
  width: 100dvw;
  height: 100dvh;
  padding: 0;
  margin: 0;
  transform: translate(-50%, -50%);
  z-index: 2147483647879;
  inset: 50%; /*Remove inset margins and stretch to full viewport */
  background-color: rgba(0, 0, 0, 0.005);
  backdrop-filter: blur(1px);
}
dialog::backdrop {
  background-color: rgba(0, 0, 0, 0.75) !important;
  backdrop-filter: blur(1px);
}
.modal-wrapper {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  justify-content: center; /* Push items to the bottom of the container */
  align-items: center; /* Center items horizontally */
  width: 100%; /* Ensure it spans the width */
  height: 100%;
  margin: 0 auto;
  max-height: 100dvh;
  overflow: auto;
  /* max-width: 1080px;
  max-height: 720px; */
  /* background-color: green; */
}
.modal-content {
  position: relative;
  box-sizing: border-box; /* Include borders in width/height calculations */
  border-radius: 0.7rem;
  color: white;
  background-color: black;
  width: 100%;
  max-width: 450px;
  padding: 0;
}
.modal-content .close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 100;
}
.modal-content button {
  pointer-events: auto;
}
.row {
  display: flex;
  justify-content: space-between; /* Space out the button and timer */
  align-items: center; /* Align elements vertically */
  width: 100%; /* Ensure the row container spans the width of its parent */
  padding: 0.5rem 0; /* Optional: Add vertical padding */
}
.row > * {
  flex: 0 1 auto; /* Allow elements to grow/shrink as needed */
  /* background-color: red; */
}
.row > *:first-child {
  margin-right: 1rem; /* Add spacing between the button and timer */
}
.row > *:last-child {
  text-align: right; /* Align the timer text to the right */
}
.row:has(> :only-child) {
  justify-content: flex-end; /* Align a single child element to the right */
}
</style>
