<!-- https://www.svgrepo.com/svg/485939/volume1 -->
<!-- viewBox= [min-x:, min-y, width, height]-->

<template>
  <svg
    fill="currentColor"
    viewBox="0 -2 36 36"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    class="icon">
    <path
      d="M23.67 10.583l-0.99 2.019c0.947 0.812 1.547 2.017 1.547 3.361 0 1.482-0.336 2.822-1.453 3.626l0.957 1.707c1.461-1.311 2.381-3.213 2.381-5.331-0.001-2.145-0.946-4.071-2.442-5.382zM31.018 3.513l-1.393 1.69c2.598 2.836 4.242 6.615 4.242 10.764 0 4.142-1.641 7.916-4.23 10.751l1.391 1.725c2.963-3.336 4.758-7.648 4.758-12.476-0.001-4.815-1.819-9.12-4.768-12.454zM27.322 6.966l-1.24 1.783c1.842 1.85 3.010 4.4 3.010 7.217 0 2.874-1.215 5.469-3.123 7.329l1.289 1.711c2.305-2.354 3.693-5.484 3.693-9.039 0-3.489-1.398-6.658-3.629-9.001zM0 12.007v8.090c0 1.031 0.896 2.354 2 2.354h6.027v-12.939h-6.027c-1.104 0-2 1.465-2 2.495zM17.341 3.619l-8.381 5.777v13.25l8.381 5.84c1.104 0 2.688-0.836 2.688-1.867v-21.133c0-1.031-1.584-1.867-2.688-1.867z"></path>
  </svg>
</template>

<style scoped>
.icon {
  display: inline;
  height: 1.1rem; /* Set height to match the font size of parent */
  width: auto; /* Automatically scale width to maintain aspect ratio */
  vertical-align: -0.29rem; /* Move the SVG slightly upwards */
  margin-right: 0.2rem;
  fill: currentColor; /* Inherit text color from parent */
}
</style>
