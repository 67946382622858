<!-- Copyright: Seán I. O'Donoghue -->

<template>
  <Burger v-if="displayWidth === 'narrow'" :views="views" :selected="view" />
  <PrimaryMenu v-else :views="views" :selected="view" />
</template>

<script setup>
const log = (message) => console.log(message); // eslint-disable-line
import {inject} from "vue";
const displayWidth = inject("displayWidth"); // from composable/display.js
import Burger from "@/components/Navigation/Burger.vue";
import PrimaryMenu from "@/components/Navigation/Primary/Menu.vue";
// setup primary views
import {ref} from "vue";
const views = ref(["About", "Blog", "Photos", "Posters", "Videos"]);
// add current year
import history from "@/assets/history.json";
views.value.push(history.latestYear);
// determine currently selected view
const view = ref(views.value.slice(-1)[0]); // initially, set to current year
import {useRoute} from "vue-router";
const route = useRoute();
function updateSelectedView() {
  log(`updateSelectedView()`);
  if (!route.name) return;
  log(`updateSelectedView: route name = ${route.name}`);

  if (route.name.includes("Video")) return "Videos"; // Fixed title

  if (!route.name.includes("Year")) {
    // Check if the route name matches any view
    if (views.value.includes(route.name)) return route.name;

    // Fallback: Look for year in the path
    const yearFromPath = route.fullPath.match(/\b\d{4}\b/); // Extract year from path
    if (yearFromPath && history.years[yearFromPath[0]]) {
      log(`Year extracted from path: ${yearFromPath[0]}`);
      return yearFromPath[0];
    }
    return;
  }

  log(`updateSelectedView: route should be a year`);
  if (!route.params.year) return;
  log(`updateSelectedView: route year = ${route.params.year}`);
  if (!history.years[route.params.year]) return; // Year not in history
  return route.params.year;
}

view.value = updateSelectedView(); // invoke initially
// recalculate selected view when route changes
import {watch} from "vue";
watch(route, () => {
  view.value = updateSelectedView(); // re-run upon route changes
});
</script>

<style scoped></style>
