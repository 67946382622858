<!-- Copyright: Seán I. O'Donoghue -->

<template>
  <h3>Test Page</h3>
  <div class="rightAlign">
    <Subtitle />
    <Archive />
  </div>

  <Topic :options="{record, category, topic}" />

  <p>
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce consequat elementum ornare. Cras
    feugiat congue lacinia. Donec ornare mattis posuere. Etiam condimentum nibh ligula, gravida
    dictum elit placerat quis. Etiam porttitor est ac nibh malesuada lacinia. Donec rhoncus sagittis
    magna. Donec semper pellentesque dignissim. Ut convallis venenatis neque id bibendum. Praesent
    at magna quam. .
  </p>
  <p>
    Nunc scelerisque eget felis ut pulvinar. In maximus sagittis sagittis. Nunc faucibus elementum
    vulputate. Donec ac erat et ligula laoreet viverra. Aliquam non felis euismod, tempor sem eget,
    pharetra tortor. Suspendisse dapibus sapien ac risus efficitur, non fringilla felis porta.
    Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Maecenas
    nec gravida purus.
  </p>
</template>

<script setup>
const log = (message) => console.log(); // eslint-disable-line
// import PrimaryNav from "@/components/Navigation/Primary.vue";
import Archive from "@/components/Navigation/Archive.vue";
import Subtitle from "@/components/Subtitle.vue";

import Topic from "@/components/People/Person/Topic.vue";
const record = {Category: "Chair;Presenter"};
const category = "Presenter";
const topic = "Keynote Speech;Panel Dis&shy;cussion";
</script>

<style scoped>
.rightAlign {
  /* width: 100px; */
  display: block;
  width: 700px;
  min-height: 200px;
  margin-left: auto;
  margin-right: 0px;
  padding: 1rem;
  text-align: right;
  background-color: rgb(0, 0, 0);
}
</style>
