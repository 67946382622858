<!-- Copyright: Seán I. O'Donoghue -->
<!-- In theory, setting this meta tag in index.html should stop tracking: -->
<!-- <meta name="twitter:dnt" content="on"> -->
<!-- https://developer.twitter.com/en/docs/twitter-for-websites/webpage-properties-->
<!-- and/or setting data-dnt="true" csp="on" here should also stop tracking -->
<!-- In practise, both don't work -->

<template>
  <div v-show="false" id="tweets">
    <a
      v-if="displayMode === 'darkMode'"
      id="container"
      class="twitter-timeline"
      data-lang="en"
      data-dnt="true"
      data-height="1100"
      data-theme="dark"
      href="https://twitter.com/vizbi?ref_src=twsrc%5Etfw&dnt=true">
      Tweets by vizbi
    </a>
    <a
      v-else
      id="container"
      class="twitter-timeline"
      data-lang="en"
      data-dnt="true"
      data-height="1100"
      data-theme="light"
      href="https://twitter.com/vizbi?ref_src=twsrc%5Etfw&dnt=true">
      Tweets by vizbi
    </a>
  </div>
</template>

<script setup>
const log = (message) => console.log(); // eslint-disable-line

import {inject, onBeforeMount} from "vue";
const displayMode = inject("displayMode");
onBeforeMount(async () => {
  log("TwitterFeed onBeforeMount()");
  const twitterScript = document.createElement("script");
  twitterScript.async = true;
  twitterScript.defer = true;
  await twitterScript.setAttribute("src", "https://platform.twitter.com/widgets.js");
  await document.head.appendChild(twitterScript); // https://stackoverflow.com/q/45047126/
});

// const observer = ref({});
// const clear_console = function () {
//   // https://stackoverflow.com/q/15657686
//   // Specify a target to observe
//   let target = document.querySelector("#tweets");
//   // Create an observer object
//   this.observer = new MutationObserver(function () {
//     setTimeout(
//       console.clear, // clear twitter console messages
//       500 // wait a bit
//     );
//   });
//   // start observing the selected properties of the target
//   this.observer.observe(target, {
//     attributes: true,
//     childList: true,
//     characterData: true
//   });
// };
</script>

<style scoped>
.unlimited #tweets,
.wide #tweets {
  /*overflow-y: scroll; /* enable vertical scrolling for large text */
}
</style>
