<!-- Place this in layouts/default so there is only one instance -->

<template>
  <ModalDialog v-if="beforeFormIsSent" :hash="hash" background="#e6e6e6" :fadeIn="fadeIn">
    <template #default>
      <div :id="`_form_${Id}_`" ref="formDivRef" :class="[`_form_${Id}`, 'form']" />
    </template>
  </ModalDialog>
  <ModalDialog v-else :hash="hash" background="#e6e6e6" :timeout="8" @close="onClose">
    <template #default>
      <div class="thanks">
        <h2>Thanks for Pre-registering</h2>
        <p>
          You will be notified you when Early Bird registration goes live for VIZBI 2025 and future
          events.
        </p>
        <p>
          To ensure that emails from
          <a href="mailto:contact@vizbi.org"> contact@vizbi.org </a>
          are not sent to spam, add this address to your known contacts list.
        </p>
      </div>
    </template>
  </ModalDialog>
</template>

<script setup>
const log = (message) => console.log(message);
import {ref, onMounted, watch} from "vue";
import {useRoute, useRouter} from "vue-router";
import ModalDialog from "@/components/ModalDialog/Index";
import {useObservers} from "@/composables/useObservers";
import {useMaxFontSize} from "@/composables/useMaxFontSize";
import {useWaitForRef} from "@/composables/useWaitForRef";
const [hash, Id, fadeIn] = ["pre-register", 33, ref(1.3)]; // 31
const [h1Ref, formDivRef] = [ref(null), ref(null)];
const [beforeFormIsSent, formWidth] = [ref(true), ref("100%")];
const [route, router] = [useRoute(), useRouter()];

useMaxFontSize(h1Ref);

function calculateFormWidth() {
  log("PreRegister: calculateFormWidth()");
  if (!formDivRef.value) return "100%";
  const form = formDivRef.value.querySelector(":scope > form");
  if (!form) {
    log("PreRegister: No immediate child <form> element found");
    return "100%";
  }
  const width = form.getBoundingClientRect().width;
  log(`PreRegister: form width = ${width}px`);
  return `${width}px`;
}
function isThankYouVisible() {
  if (!formDivRef.value) return false;
  const thankYouElement = formDivRef.value.querySelector("._form-thank-you");
  log(`PreRegister: thankYouElement.style.display = ${thankYouElement?.style?.display}`);
  return thankYouElement === null || thankYouElement.style.display !== "block";
}
function handleFormChanges() {
  log(`PreRegister: handleFormChanges() - Callback for mutation observer`);
  formWidth.value = calculateFormWidth();
  log(`PreRegister: formWidth = ${formWidth.value}`);
  beforeFormIsSent.value = isThankYouVisible();
}
function insertActiveCampaignForm() {
  log("PreRegister: Inserting ActiveCampaign form on mount");
  const recaptchaScript = document.createElement("script");
  recaptchaScript.async = true;
  recaptchaScript.src = `https://masteryevents.activehosted.com/f/embed.php?id=${Id}`;
  document.head.appendChild(recaptchaScript);

  const trackCampaignEmail = "";
  const trackCampaign = document.createElement("script");
  trackCampaign.async = true;
  trackCampaign.type = "text/javascript";
  trackCampaign.src = `//trackcmp.net/visit?actid=251984889&e=${encodeURIComponent(
    trackCampaignEmail
  )}&r=${encodeURIComponent(document.referrer)}&u=${encodeURIComponent(window.location.href)}`;
  const trackScripts = document.getElementsByTagName("script");
  if (trackScripts.length) {
    trackScripts[0].parentNode.appendChild(trackCampaign);
  } else {
    const headElement = document.getElementsByTagName("head");
    if (headElement.length) headElement[0].appendChild(trackCampaign);
  }
}
function initializeActiveCampaignTracking() {
  log("PreRegister: initializeActiveCampaignTracking()");
  // if (config.public.isDevelopment) return;
  const script = document.createElement("script");
  script.src = "https://diffuser-cdn.app-us1.com/diffuser/diffuser.js";
  script.async = true;
  document.head.appendChild(script);
  log(`PreRegister: script loaded`);
  const activeCampaignID = process.env.VUE_APP_ACTIVECAMPAIGNID;
  log(`PreRegister: activeCampaignID = ${activeCampaignID}`);
  window.visitorGlobalObjectAlias = "vgo";
  window.vgo =
    window.vgo ||
    function () {
      (window.vgo.q = window.vgo.q || []).push(arguments);
    };
  window.vgo("setAccount", activeCampaignID);
  window.vgo("setTrackByDefault", true);
  window.vgo("process");
}
function onClose() {
  log("PreRegister: onClose()");
  beforeFormIsSent.value = !beforeFormIsSent.value; // reset form
  insertActiveCampaignForm();
}
async function switchToRegistrationPage() {
  if (!route.hash.includes(hash)) return;
  const registrationPage = "/2025/registration";
  if (route.path.includes(registrationPage)) return (fadeIn.value = 0);
  log(`PreRegister: Navigating to registration page`);
  await router.push({path: registrationPage, hash: route.hash});
}

onMounted(async () => {
  log("PreRegister: onMounted()");
  initializeActiveCampaignTracking();
  try {
    await useWaitForRef(formDivRef); // Wait for formDivRef to become available
    log("PreRegister: formDivRef is ready");
    insertActiveCampaignForm();
    handleFormChanges();
    await switchToRegistrationPage();
  } catch (error) {
    console.error("PreRegister: formDivRef not defined or invalid", error);
  }
});

useObservers(formDivRef, handleFormChanges); // Set up observers once formDivRef available

watch(() => route.hash, switchToRegistrationPage);
</script>

<style scoped>
div {
  /* background-color: red; */
  width: 100%;
}
div.form {
  background-color: green;
}
div.thanks > * {
  color: black;
  padding: 20px 20px 0 20px;
}
.thanks h2 {
  font-size: 1.6rem;
  margin-bottom: 0.5rem;
}
/* :is(.narrow, .medium) .thanks h2 {
  font-size: var(--medium-large);
} */
.thanks p {
  /* font-size: var(--medium); */
  line-height: 1.6;
}
div + div {
  margin-top: 2rem;
  width: 100%; /* Take the width of the container */
  text-align: left;
}
._form-thank-you {
  color: red;
}
</style>
