<template>
  <span v-if="phonetically" class="container">
    <button v-if="isFileAvailable" @click="playAudio"><Sound /> '{{ phonetically }}'</button>
    <audio v-if="isFileAvailable" ref="audio" :src="file" preload="auto" />
  </span>
</template>

<script setup>
const log = (message) => console.log(message); // eslint-disable-line
import {ref, watch} from "vue";
import Sound from "@/components/App/Sound.vue";
const props = defineProps(["phonetically", "file"]);
const audio = ref(null);
const isFileAvailable = ref(false);

const checkFileAvailability = async () => {
  // Check if audio file is available
  if (!props.file) log(`Pronounced: no file specified.`);
  if (!props.file) return (isFileAvailable.value = false);
  try {
    const response = await fetch(props.file, {method: "HEAD"});
    log(`Pronounced: Fetch response for ${props.file}: ${response.status}`);
    isFileAvailable.value = response.ok;
    if (!response.ok) log(`Pronounced: file not available.`);
  } catch (error) {
    log(`Error fetching file: ${error.message}`);
    isFileAvailable.value = false;
  }
};

const playAudio = async () => {
  // Play sound
  if (!audio.value) return;
  try {
    await audio.value.play();
  } catch (error) {
    console.error(`Pronounced: Audio playback failed; ${error}`);
  }
};

watch(() => props.audioFile, checkFileAvailability, {immediate: true});
</script>

<style scoped>
.container {
  display: inline-block;
}
.pronounced {
  font-style: italic;
}
button {
  background-color: var(--brand-color);
  color: white;
  font-size: 0.75rem;
  font-weight: 700;
  border: none;
  border-radius: 8px;
  padding: 2px 10px;
  cursor: pointer;
}
button:hover {
  background-color: var(--brand-hover-color);
}
.error-message {
  color: red;
  font-size: 14px;
  margin-top: 10px;
}
</style>
