<template>
  <div class="table" :style="tableStyle">
    <!-- Header Row -->
    <div class="row header">
      <div v-for="(header, index) in headers" :key="index" class="cell header-cell">
        {{ header }}
      </div>
    </div>
    <!-- Data Rows -->
    <div v-for="(row, rowIndex) in rows" :key="rowIndex" class="row">
      <div
        v-for="(cell, columnIndex) in row"
        :key="columnIndex"
        class="cell"
        :style="isHighlighted(columnIndex) ? highlightStyle : null">
        {{ cell }}
      </div>
    </div>
  </div>
</template>

<script setup>
const log = (message) => console.log(message); // eslint-disable-line
const props = defineProps([
  "headers",
  "rows",
  "gridTemplateColumns",
  "highlightedColumns",
  "highlightStyle"
]);
import {computed} from "vue";
// Helper to calculate fallback grid-template-columns
const defaultGridTemplateColumns = computed(() => {
  const headerCount = Array.isArray(props.headers) ? props.headers.length : 0;
  return `repeat(${headerCount}, 1fr)`;
});
// Main table style computation
const tableStyle = computed(() => ({
  gridTemplateColumns: props.gridTemplateColumns || defaultGridTemplateColumns.value,
  width: "100%", // Ensures table doesn't exceed container width
  overflowX: "auto" // Handles overflow gracefully on small screens
}));

// Helper function to check if a cell is in a discounted column
const isHighlighted = (columnIndex) => {
  log(`Table: columnIndex = ${columnIndex}`);
  const highlighted = props.highlightedColumns.includes(columnIndex);
  log(`Table: highlighted = ${highlighted}`);
  return highlighted;
};
</script>

<style scoped>
.table {
  --cell-padding: 0.22rem;
}
.table {
  display: grid;
  grid-auto-rows: auto;
  gap: var(--cell-padding); /* Adjust as needed */
  width: 100%; /* Ensures table fits the parent */
  font-size: 1.1rem;
}
.row {
  display: contents; /* Rows inherit the grid structure */
}
.header {
  font-weight: bold;
  font-style: italic;
  color: #333;
}
.header-cell {
  font-weight: bold;
  color: #000;
  text-align: center;
}
.header-cell:first-child {
  font-style: normal;
  text-transform: uppercase;
}
.cell {
  padding: var(--cell-padding);
  text-align: center;
  color: #555;
}
.cell:first-child {
  text-align: left;
}
.discounted-cell {
  background-color: #ccc;
  background-color: #ed4a4a;
  color: #000;
  font-weight: bold;
  border-radius: 12px;
  padding: var(--cell-padding) var(--cell-padding);
}
</style>
