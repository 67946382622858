<!-- <sup>{{ index + 1 }}</sup> -->

<!-- eslint-disable vue/no-v-html -->
<template>
  <div v-if="!title">YOU NEED TO SPECIFY A 'title' prop</div>
  <div v-else-if="!events[title]">'{{ title }}' not found in events.json</div>
  <table v-else>
    <colgroup>
      <col class="leftColumn" span="1" />
      <col class="secondColumn" span="1" />
      <col class="rightColumn" span="1" />
      <!-- The fourth column will fill the remaining space -->
    </colgroup>
    <tfoot>
      <!-- <tr>
        <td colspan="3">
          <ol>
            <li v-for="(subtitle, idx) in subtitles" :key="`${idx}-footnote`">
              {{ item[subtitle]["footnote"] }}
            </li>
          </ol>
        </td>
      </tr> -->
    </tfoot>
    <tbody>
      <tr>
        <th class="alignLeft"><span v-html="abbreviate(title)" /></th>
        <th v-for="(subtitle, index) in subtitles" :key="index">
          <span v-html="abbreviate(subtitle)" />
        </th>
        <th />
      </tr>
      <tr v-for="(category, index) of categories" :key="`${fee}-${index}`">
        <td class="alignLeft"><span v-html="abbreviate(category)" /></td>
        <td v-for="(subtitle, idx) in subtitles" :key="`${idx}-${subtitle}`">
          {{ addCurrency(item[subtitle]["fees"][category]) }}
        </td>
        <td />
      </tr>
    </tbody>
  </table>
</template>

<script setup>
const log = (message) => console.log(message); // eslint-disable-line
const props = defineProps(["title"]); // eslint-disable-line
import events from "@/assets/years/2025/Registration/events.json";
const item = events[props.title];
const subtitles = Object.keys(item);
log(`subtitles = ${subtitles}`);
log(`subtitles[0] = ${subtitles[0]}`);
log(`item[subtitles[0]] = ${JSON.stringify(item[subtitles[0]])}`);
const fees = item[subtitles[0]]["fees"];
log(`fees = ${JSON.stringify(fees)}`);
const categories = Object.keys(fees);
log(`categories = ${JSON.stringify(categories)}`);
// const footnoteSymbol = ["*", "†"];
// abbreviate()
import {inject} from "vue";
const displayWidth = inject("displayWidth");
function abbreviate(heading) {
  log(`displayWidth = ${displayWidth.value}`);
  if (displayWidth.value === "unlimited") return heading;
  if (displayWidth.value === "wide") return heading;
  heading = heading.replace(/\s+participation/i, "");
  heading = heading.replace(/VIZBI\s*/i, "");
  heading = heading.replace(/Academic\/Freelancer/i, "Academic/&shy;Freelancer");
  heading = heading.replace(/Masterclass/i, "Master&shy;class");
  heading = heading.replace(/Conference/i, "Confer&shy;ence");
  heading = heading.replace(/Workshop/i, "Work&shy;shop");
  heading = heading.replace(/Online/i, "On&shy;line");
  return heading;
}
const addCurrency = (price) => {
  return price ? `£${price}` : "";
};
// nbsp()
// function nbsp(text) {
//   return text.replace(/ /g, "\u00A0");
// }
</script>

<style scoped>
table {
  table-layout: fixed;
  border-collapse: collapse;
  margin-bottom: 2em;
  width: 100%;
  text-align: center;
  background: var(--background-color);
}
th {
  padding-right: 12px;
  color: #999;
  font-size: 100%;
  font-weight: 900;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}
.leftColumn {
  width: 240px;
}
:is(.secondColumn, .thirdColumn, .rightColumn) {
  width: 135px;
}
.wide .leftColumn {
  width: 170px;
}
.medium .leftColumn {
  width: 10rem;
}
.medium .secondColumn {
  width: 6rem;
}
.medium .thirdColumn {
  width: 9rem;
}
.medium .rightColumn {
  width: 5rem;
}

.narrow .leftColumn {
  width: 7rem;
}
.narrow .secondColumn {
  width: 4rem;
}
.narrow .thirdColumn {
  width: 8rem;
}
.narrow .rightColumn {
  width: 4rem;
}
.narrow th {
  font-size: 85%;
}
td,
th {
  padding: 6px;
}
tr {
  vertical-align: top;
}
.alignLeft {
  text-align: left;
}
tr:nth-child(even) {
  background: var(--background-color-subtle);
}
tfoot td {
  padding-top: 0.05rem;
  font-style: italic;
  background: var(--background-color);
}
ol {
  padding-left: 0; /* Remove default padding */
  text-align: left;
  counter-reset: item; /* Initialize the counter */
}
ol li {
  list-style: none; /* Remove default list styling */
  position: relative; /* Needed for absolute positioning of pseudo-elements */
  margin-left: 0px; /* Adjust as needed to align with your text */
  padding-left: 10px; /* Space for the number */
  margin-bottom: 0.25rem;
  font-size: 90%;
}
ol li::before {
  content: counter(item) "."; /* Add a period after the number */
  counter-increment: item; /* Increment the counter */
  position: absolute;
  left: 0; /* Align numbers to the left edge */
  top: 0; /* Position at the top for superscript effect */
  font-size: smaller; /* Smaller font size for superscript effect */
  vertical-align: super; /* Superscript alignment */
}
</style>
