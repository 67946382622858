<!-- Place this in layouts/default so there is only one instance -->

<template>
  <!-- Intermediate state: Spinner with backdrop -->
  <ModalDialog v-if="isLoading" background="#e6e6e6">
    <template #default>
      <div class="loading-spinner">
        <!-- Simple spinner for the intermediate state -->
        <div class="spinner"></div>
      </div>
    </template>
  </ModalDialog>

  <!-- Form modal -->
  <ModalDialog v-else-if="beforeFormIsSent" :hash="hash" background="#e6e6e6">
    <template #default>
      <div :id="`_form_${Id}_`" ref="formDivRef" :class="[`_form_${Id}`, 'form']" />
    </template>
  </ModalDialog>

  <!-- Thank you modal -->
  <ModalDialog v-else :hash="hash" background="#e6e6e6" :timeout="8" @close="onClose">
    <template #default>
      <div class="thanks">
        <h2>Thanks for your message</h2>
        <p>
          To ensure that emails from
          <a href="mailto:contact@vizbi.org"> contact@vizbi.org </a>
          are not sent to spam, add this address to your known contacts list.
        </p>
      </div>
    </template>
  </ModalDialog>
</template>

<script setup>
const log = (message) => console.log(message);
import {ref, watch, onMounted} from "vue";
import {useRoute, useRouter} from "vue-router";
import ModalDialog from "@/components/ModalDialog/Index";
import {useObservers} from "@/composables/useObservers";
import {useMaxFontSize} from "@/composables/useMaxFontSize";
import {useWaitForRef} from "@/composables/useWaitForRef";

const [hash, Id] = ["pre-register", 31];
const [h1Ref, formDivRef] = [ref(null), ref(null)];
const [beforeFormIsSent, formWidth, isLoading] = [ref(true), ref("100%"), ref(false)];
const [route, router] = [useRoute(), useRouter()];

useMaxFontSize(h1Ref);

function calculateFormWidth() {
  if (!formDivRef.value) return "100%";
  const form = formDivRef.value.querySelector(":scope > form");
  return form ? `${form.getBoundingClientRect().width}px` : "100%";
}
function isThankYouVisible() {
  // Callback for observers
  if (!formDivRef.value) return false;
  const thankYouElement = formDivRef.value.querySelector("._form-thank-you");
  return !thankYouElement || thankYouElement.style.display !== "block";
}
function handleFormChanges() {
  formWidth.value = calculateFormWidth();
  beforeFormIsSent.value = isThankYouVisible();
}
function insertActiveCampaignForm() {
  // Add the ActiveCampaign form on mount
  log("StayUpdated: Inserting ActiveCampaign form");
  const recaptchaScript = document.createElement("script");
  recaptchaScript.async = true;
  recaptchaScript.src = `https://masteryevents.activehosted.com/f/embed.php?id=${Id}`;
  document.head.appendChild(recaptchaScript);
  const trackCampaignEmail = "";
  const trackCampaign = document.createElement("script");
  trackCampaign.async = true;
  trackCampaign.type = "text/javascript";
  trackCampaign.src = `//trackcmp.net/visit?actid=251984889&e=${encodeURIComponent(
    trackCampaignEmail
  )}&r=${encodeURIComponent(document.referrer)}&u=${encodeURIComponent(window.location.href)}`;
  const trackScripts = document.getElementsByTagName("script");
  if (trackScripts.length) {
    trackScripts[0].parentNode.appendChild(trackCampaign);
  } else {
    const headElement = document.getElementsByTagName("head");
    if (headElement.length) headElement[0].appendChild(trackCampaign);
  }
}
function initializeActiveCampaignTracking() {
  log("ContactUs: initializeActiveCampaignTracking()");
  // if (config.public.isDevelopment) return;
  const script = document.createElement("script");
  script.src = "https://diffuser-cdn.app-us1.com/diffuser/diffuser.js";
  script.async = true;
  document.head.appendChild(script);
  log(`ContactUs: script loaded`);
  const activeCampaignID = process.env.VUE_APP_ACTIVECAMPAIGNID;
  log(`ContactUs: activeCampaignID = ${activeCampaignID}`);
  window.visitorGlobalObjectAlias = "vgo";
  window.vgo =
    window.vgo ||
    function () {
      (window.vgo.q = window.vgo.q || []).push(arguments);
    };
  window.vgo("setAccount", activeCampaignID);
  window.vgo("setTrackByDefault", true);
  window.vgo("process");
}
function onClose() {
  // On modal close
  beforeFormIsSent.value = !beforeFormIsSent.value;
  insertActiveCampaignForm();
}

onMounted(async () => {
  log("PreRegister: onMounted()");
  try {
    await useWaitForRef(formDivRef);
    log("formDivRef is ready");
    insertActiveCampaignForm();
    handleFormChanges();
    useObservers(formDivRef, handleFormChanges);

    if (route.hash === `#${hash}`) {
      log("Hash detected on mount, triggering modal flow");
      isLoading.value = true;

      if (route.path !== "/2025/registration") {
        log("Navigating to registration page on mount");
        await router.push({path: "/2025/registration", hash: route.hash});
      }

      setTimeout(() => {
        log("Mount complete, switching to modal content");
        isLoading.value = false;
        beforeFormIsSent.value = true;
      }, 1000);
    }
  } catch (error) {
    console.error("Error during onMounted:", error);
  }
});

watch(
  () => route.hash,
  async (newHash) => {
    if (newHash === `#${hash}`) {
      log("Hash detected, triggering modal flow");
      isLoading.value = true;

      if (route.path !== "/2025/registration") {
        log("Navigating to registration page");
        await router.push({path: "/2025/registration", hash: newHash});
      }

      // Ensure the modal content is rendered after loading
      setTimeout(() => {
        log("Loading complete, switching to modal content");
        isLoading.value = false;
        beforeFormIsSent.value = true;
      }, 1000);
    }
  },
  {immediate: true}
);
</script>

<style scoped>
div {
  /* background-color: red; */
  width: 100%;
}
div.form {
  background-color: green;
}
div.thanks {
  color: var(--vt-c-black);
  /* background-color: yellow; */
  padding: 20px 20px 0 20px;
}
h2 {
  font-size: var(--large);
  margin-bottom: 1rem;
}
:is(.narrow h2, .medium h2) {
  font-size: var(--medium-large);
}
p {
  font-size: var(--medium);
  line-height: 1.6;
}
a {
  font-size: var(--small);
  color: var(--link-color) !important;
}
div + div {
  margin-top: 2rem;
  width: 100%; /* Take the width of the container */
  text-align: left;
}
ol {
  list-style-position: outside; /* Numbers outside the content box */
  margin-left: 1rem; /* Adjust margin for number alignment */
  padding-left: 0; /* No extra padding needed */
}
li {
  text-indent: 0; /* First line aligns naturally with numbers */
}
:is(p, li) {
  margin-bottom: 0.5rem;
}
._form-thank-you {
  color: red;
}
li {
  clear: both; /* active campaign uses floats that need to be cleared */
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.spinner {
  width: 50px;
  height: 50px;
  border: 6px solid #e6e6e6;
  border-top: 6px solid var(--brand-color);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
