<template>
  <div v-if="options" class="button-row-container">
    <div class="button-row">
      <button
        v-for="(option, index) in shortOptions"
        :key="index"
        class="button-option"
        :class="{
          active: localSelectedOptions.includes(props.options[index]),
          'left-button': index === 0,
          'right-button': index === options.length - 1
        }"
        @click="toggleOption(option)">
        {{ option }}
      </button>
      <template v-for="(option, index) in options">
        <div
          v-if="index < shortOptions.length - 1 && showSeparator(index)"
          :key="`separator-${index}`"
          class="separator" />
      </template>
    </div>
  </div>
</template>

<script setup>
import {ref, defineProps, defineEmits, computed, inject, onMounted} from "vue";
import {useStorage} from "@vueuse/core";
import {useShort} from "@/composables/useShort";
const log = (message) => console.log(message); // eslint-disable-line
// Props and emits
const props = defineProps(["options", "selectedOptions", "abbreviations"]);
const emit = defineEmits(["update:selectedOptions"]);
// Persistent state for selected options
const persistedSelectedOptions = useStorage("button-row-selected-options", []);
// Ensure persistedSelectedOptions is always an array
if (!Array.isArray(persistedSelectedOptions.value)) {
  persistedSelectedOptions.value = []; // Reset if invalid
}
// Local state for selected options
const localSelectedOptions = ref([...persistedSelectedOptions.value]);
// Inject dependencies
const displayWidth = inject("displayWidth"); // from composable/display.js
const {short} = useShort(props.abbreviations);

// Shortened options based on display width
const shortOptions = computed(() => {
  const output = props.options.map((option) => short(option));
  log(`ButtonRow: shortOptions = ${JSON.stringify(output)}`);
  return output;
});
// Toggle selection of an option
const toggleOption = (shortenedOption) => {
  const index = shortOptions.value.indexOf(shortenedOption);
  const originalOption = props.options[index];

  if (localSelectedOptions.value.includes(originalOption)) {
    localSelectedOptions.value = localSelectedOptions.value.filter((o) => o !== originalOption);
  } else {
    localSelectedOptions.value.push(originalOption);
  }

  // Persist the updated state
  persistedSelectedOptions.value = [...localSelectedOptions.value];

  // Emit the updated selection
  emit("update:selectedOptions", [...localSelectedOptions.value]);
};
// Determine whether to show separators
const showSeparator = (index) => {
  return (
    !localSelectedOptions.value.includes(props.options[index]) &&
    !localSelectedOptions.value.includes(props.options[index + 1])
  );
};

// Apply persisted state on mount
onMounted(() => {
  log(`ButtonRow: selectedOptions = ${props.selectedOptions}`);
  if (
    !Array.isArray(persistedSelectedOptions.value) ||
    persistedSelectedOptions.value.length === 0
  ) {
    localSelectedOptions.value = props.selectedOptions; // use default options from parent
    return;
  }
  // Otherwise, restore from storage
  localSelectedOptions.value = [...persistedSelectedOptions.value];
  emit("update:selectedOptions", [...localSelectedOptions.value]); // Sync with parent
});
</script>

<style scoped>
.button-row-container {
  --border-radius: 32px; /* Border radius for the left and right buttons */
  --padding-vertical: 9px; /* Vertical padding for buttons */
  --padding-horizontal: 16px; /* Horizontal padding for buttons */
  --separator-color: #141414; /* Color of the vertical separators */
  --container-padding: 1px; /* Padding for the outer container */
}

:is(*, *::before, *::after) {
  box-sizing: border-box;
}

.button-row-container {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #bfbdbd;
  border-radius: var(--border-radius);
  padding: var(--container-padding);
}
.button-row {
  display: flex;
  gap: 0;
  position: relative;
  width: 100%;
}
.button-option {
  flex: 1;
  padding: var(--padding-vertical) var(--padding-horizontal);
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  border: 1px solid transparent;
  border-left: none;
  text-align: center;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.button-option.left-button {
  border-radius: var(--border-radius) 0 0 var(--border-radius);
}

.button-option.right-button {
  border-radius: 0 var(--border-radius) var(--border-radius) 0;
}
.button-option:not(.left-button):not(.right-button) {
  border-radius: 0;
}
.button-option.active {
  background-color: #d0d0d0;
  color: #449e80;
}
.button-option:not(.active) {
  background-color: #f0f0f0;
  color: #727272;
  font-weight: 600;
}
.separator {
  position: absolute;
  top: 25%;
  bottom: 25%;
  width: 3px;
  background-color: var(--separator-color);
}
</style>
