<template>
  <div v-if="structure !== 'unknown'">
    <Table
      :headers="processedData.headers"
      :rows="processedData.rows"
      :grid-template-columns="gridTemplateColumns"
      :highlighted-columns="[2]"
      :highlight-style="highlightStyle" />
  </div>
  <div v-else>
    <h2 v-if="title">title = {{ title }}</h2>
    <p>structure = {{ structure }}</p>
    <!-- <p>columns = {{ JSON.stringify(columns, null, 2) }}</p> -->
    <!-- <p>headers = {{ JSON.stringify(headers, null, 2) }}</p> -->
    <pre>data = {{ JSON.stringify(data, null, 2) }}</pre>
    <!-- <p>rows = {{ JSON.stringify(rows, null, 2) }}</p> -->
  </div>
</template>

<script setup>
const log = (message) => console.log(message); // eslint-disable-line
const props = defineProps(["title", "data", "abbreviations"]);
import {computed, inject, watch} from "vue";
import Table from "@/components/App/Table";
import {useShort} from "@/composables/useShort";
import history from "@/assets/history.json";
import {useDataTransition} from "@/composables/useDataTransition";
const validData = props.data && typeof props.data === "object";
const validTitle = props.title && typeof props.title === "string";
const {intermediateData} = useDataTransition(() => props.data || {});
const $ = (number) => `${history.currency}${number}`;
const displayWidth = inject("displayWidth"); // from composable/display.js
const {short} = useShort(props.abbreviations);
const highlightStyle = {
  "background-color": "#cfcfcf",
  color: "black",
  "border-radius": "0px",
  transition: "background-color 0.3s ease, color 0.3s ease"
};

const processedData = computed(() => {
  if (!intermediateData.value || !validTitle) return {headers: [], rows: []};

  const firstKey = Object.keys(intermediateData.value)[0];
  const firstValue = intermediateData.value[firstKey];

  if (typeof firstValue === "number") {
    // Case 1: Simple key-value pairs
    return {
      headers: [props.title, ""],
      rows: Object.entries(intermediateData.value).map(([key, value]) => [
        short(key),
        `${$(value)}`
      ])
    };
  }

  if (typeof firstValue === "object" && !Array.isArray(firstValue)) {
    // Case 2: Nested key-value pairs
    const headers = Object.keys(intermediateData.value);
    const categories = Array.from(
      new Set(headers.flatMap((col) => Object.keys(intermediateData.value[col] || {})))
    );
    const shortHeaders = headers.map((header) => short(header));
    return {
      headers: [short(props.title), ...shortHeaders],
      rows: categories.map((category) => [
        short(category),
        ...headers.map((col) => $(intermediateData.value[col]?.[category]) ?? "N/A")
      ])
    };
  }

  return {headers: [], rows: []};
});

const gridTemplateColumns = computed(() => {
  if (processedData.value.headers.length === 2) return "2fr 1fr"; // First column is twice as wide as the second
  return null; // Default to evenly spaced columns
});

watch(
  processedData,
  (newVal) => {
    console.log("Processed data updated:", newVal);
  },
  {deep: true}
);
</script>

<style scoped>
pre {
  background-color: #cfcfcf; /* totally not needed; just here to help choose color */
}
</style>
