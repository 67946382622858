<!-- Copyright: Sean O'Donoghue & Sandeep Kaur -->

<!-- eslint-disable vue/no-v-html -->
<template>
  <TimeZone
    :time-zone-selected="timeZoneSelected"
    :day-selected="daySelected"
    @update="switchTimeZone" />
  <ProgramParse
    v-if="timeZoneSelected"
    :key="timeZoneSelected"
    :time-zone-selected="timeZoneSelected"
    :session="session"
    @finished="(payload) => (program = payload)" />
  <div id="programTable">
    <DayTags
      v-if="showDayTags"
      :program-days="getSortedDays()"
      :time-zone-selected="timeZoneSelected"
      :day-selected="daySelected"
      @update="toggleDayView" />
    <ul v-if="eventModes" class="legend">
      <li>Icons:</li>
      <li v-for="(eventMode, eventIndex) in eventModes" :key="`event${eventIndex}`">
        <span v-if="eventMode.match(/online|video/i)">
          <img :src="iconUrl(eventMode)" class="streamed" />
          =
        </span>
        <span v-else-if="eventModes.length > 1">No icon = </span>
        {{ eventMode }}
      </li>
    </ul>
    <div v-for="(day, dayId) in getSortedDays()" :key="dayId">
      <div v-show="!daySelected || daySelected === day">
        <h5 @click="toggleDayView(day)">
          {{ formatDayForToggleDisplay(program[day].date) }}:
          <span class="h5_subheading" v-html="program[day].title" />
        </h5>
        <table v-show="!daySelected || daySelected" class="eventTable">
          <colgroup>
            <col class="first" />
            <col class="second" />
            <col class="third" />
          </colgroup>
          <TableRows
            :program-day="program[day]"
            :session="session"
            :description="description"
            :time-zone-selected="timeZoneSelected" />
        </table>
      </div>
    </div>
  </div>
</template>

<script setup>
const log = (message) => console.log(message); // eslint-disable-line
import ProgramParse from "@/components/Program/Parse";
import TableRows from "@/components/Program/TableRows";
import TimeZone from "@/components/Program/TimeZone";
import DayTags from "@/components/Program/DayTags";

const props = defineProps(["description", "showDayTags", "session"]);
log(`session = ${props.session}`); // Logs the session prop

import {ref} from "vue";
const [daySelected, timeZoneSelected] = [ref(null), ref(null)];
const program = ref({});
const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

import {computed} from "vue";
const eventModes = computed(() => {
  log("Called eventModes");
  let output = [];
  for (const day in program.value) {
    log(`eventModes: day = ${day}`);
    program.value[day]["events"].forEach((event) => {
      log(`eventModes: event.mode = ${event.mode}`);
      if (event.mode && !output.includes(event.mode)) {
        output.push(event.mode);
      }
    });
  }
  // return empty output if all events are on-site only
  if (output.length === 1 && output[0] === "In-person only") output = [];
  log(`eventModes: output = ${output}`);
  // eslint-disable-next-line no-unused-vars
  return output.sort((a, b) => {
    // Added 'b' to satisfy sorting requirements
    if (a.match(/^online/i)) {
      return -1; // sort a before b
    } else if (a.match(/^on-?site only/i)) {
      return 1; // sort a after b
    } else {
      return 0;
    }
  });
});

function getSortedDays() {
  let sortedDays = Object.keys(program.value).sort((a, b) => {
    let orderA = days.indexOf(a); // day 'a' index in 'days' array
    let orderB = days.indexOf(b); // day 'b' index in 'days' array
    return program.value[a][orderA] - program.value[b][orderB];
  });
  log(`sortedDays: ${sortedDays}`);
  return sortedDays;
}
function formatDayForToggleDisplay(day) {
  let arr = day.split(" ");
  return arr[0] + " " + arr[2] + " " + arr[3];
}
function toggleDayView(day) {
  log(`toggleDayView(${day})`);
  if (day === "Show All") day = ""; // Show All button pressed
  daySelected.value = day; // set selected day
}

function iconUrl(location) {
  log(`iconUrl(${location})`);
  let file = "";
  if (location.match(/online\s*\+\s*on-?site/i)) {
    log(`iconUrl: match Online + on-site`);
    file = "onsite+online.png";
  } else if (location.match(/online\s*only/i)) {
    log(`iconUrl: match online only`);
    file = "online_only.png";
  } else if (location.match(/video/i)) {
    log(`iconUrl: video recording`);
    file = "recording.png";
  } else {
    return "";
  }
  return require(`@/assets/img/${file}`);
}

function switchTimeZone(newTimeZone) {
  log(`switchTimeZone(${newTimeZone})`);
  timeZoneSelected.value = newTimeZone;
}
</script>

<style scoped>
#programTable {
  clear: both; /* https://stackoverflow.com/q/2580772 */
  margin-top: 3.8em;
  margin-bottom: 2em;
}
tr:nth-child(even) {
  /*  https://stackoverflow.com/q/32867605 */
  background-color: var(--background-color-subtle);
}
.eventTable {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 1em;
}
.eventTable td,
th {
  padding: 6px;
  vertical-align: top;
}
/* https://stackoverflow.com/q/27788092 */
.eventTable tr td:first-child {
  width: 19%;
}
.eventTable tr td:nth-child(2) {
  width: 2%;
}
.eventTable tr td:nth-child(3) {
  width: 79%;
}
h4 {
  display: inline;
  font-weight: 600 !important;
}
h5 {
  font-weight: 600 !important;
  /* this don't work - don't know why */
  margin-top: 22px;
  padding-top: 0px;
  margin-bottom: 6px;
  padding-bottom: 0px;
}
.h5_subheading {
  font-weight: 200 !important;
}
.event {
  display: flex; /* outer container for event arrow / title / description */
}
.eventTitle {
  flex-direction: column;
}
span.arrows {
  display: flex;
  flex-direction: column;
  color: #666666;
  padding-left: 0px;
  margin-left: 0px;
  cursor: pointer;
}
/* https://stackoverflow.com/q/27492191 */
.chevronArrow {
  border-right: 0.1em solid #7f7f7f;
  border-top: 0.1em solid #7f7f7f;
  width: 0.4em;
  height: 0.4em;
  margin-top: 0.4em;
  margin-left: 0em;
  margin-right: 0.6em;
  margin-bottom: 0.1em;
}
.hide {
  display: none;
}
.right {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.down {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}
span.chevronArrow:hover,
.down {
  border-color: var(--brand-color);
}
/* key adjustments for small screen */
.narrow h4,
.narrow h4 {
  font-size: 1.25rem;
}
.narrow .eventTable col.first,
.medium .eventTable col.first {
  width: 8%;
}
.narrow .eventTable col.second,
.medium .eventTable col.second {
  width: 3%;
}
.narrow .eventTable col.third,
.medium .eventTable col.third {
  width: 89%;
}
.narrow .wideScreenOnly,
.medium .wideScreenOnly {
  /* show only start times on narrow screens */
  display: none;
}
img.streamed {
  width: 14px;
}
img.online-only {
  filter: saturate(0%);
}
ul.legend {
  margin-left: 0px;
  padding-left: 0px;
  margin-top: 12px;
  margin-bottom: 0px;
  padding-bottom: 0px;
  font-weight: 300;
}
ul.legend li {
  display: inline;
}
li + li {
  margin-left: 6px;
}
li:not(:first-of-type) + li::before {
  margin-right: 6px;
  content: " | "; /* https://stackoverflow.com/q/9171699 */
}
</style>
