// Takes new and old data and calculations an animation between these two states

import {ref} from "vue";

export function useDataAnimation(oldValues, newValues, duration = 500, steps = 10) {
  const animatedValues = ref([...oldValues]); // Start with old values
  const stepTime = duration / steps;

  console.log("Animating values:", {oldValues, newValues});

  let currentStep = 0;
  const interval = setInterval(() => {
    if (currentStep >= steps) {
      clearInterval(interval);
      animatedValues.value = [...newValues];
      console.log("Animation completed. Final values:", animatedValues.value);
      return;
    }

    currentStep++;
    console.log(`Animation step ${currentStep}:`, animatedValues.value);

    animatedValues.value = animatedValues.value.map((value, index) => {
      const start = parseFloat(oldValues[index]) || 0;
      const end = parseFloat(newValues[index]) || 0;
      const progress = currentStep / steps;
      return Math.round(start + progress * (end - start));
    });
  }, stepTime);

  return animatedValues;
}
