<!-- <div ref="playerContainer" style="padding: 56.25% 0 0 0; position: relative">
      <iframe
        :src="videoUrl"
        frameborder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
        title="VIZBI2025CampaignReel_v1_nobookend" />
    </div> -->

<!-- files in public/2025 -->

<!-- webm="/2025/Hero.webm"  -->

<template>
  <div class="container">
    <p>
      The 15th international meeting on 'Visualizing Biological Data' (VIZBI 2025)
      {{ tense("takes", "took") }} place April 8&ndash;11 in Cambridge UK. The meeting
      {{ tense("features", "featured") }} a one-day
      <router-link to="/2025/Masterclass">Masterclass training event</router-link>
      followed by a three-day workshop.
      <!-- The event {{ tense("will also be", "were") }} streamed online. -->
    </p>

    <HeroVideo
      autoplay
      muted
      loop
      playsinline
      mp4="/2025/Hero.mp4"
      jpg="/2025/Hero.jpg"
      credit="Leonora Martínez-Nuñez" />

    <!-- <HeroImage
      hero-image="Hero.jpg"
      flyer-low-res="Flyer.jpg"
      flyer-hi-res="Flyer.pdf"
      poster-low-res="Poster.jpg"
      poster-hi-res="Poster.pdf"
      media-kit="https://bit.ly/vizbi-media-kit"
      credit="Leonora Martínez-Nuñez" /> -->
    <RegisterNowWrapper />

    <p>
      VIZBI 2025 {{ tense("will feature", "featured") }} keynotes on the combined use of
      visualization with explainable AI for exploring complex multidimensional data. The meeting
      {{ tense("includes", "included") }} talks from 21 world-leading researchers showcasing
      visualizations transforming how life scientists view data, and driving key advances in
      molecular biology, systems biology, biomedical science, and ecology.
      <!-- Confirmed keynote
      speakers: <a href="https://jku-vds-lab.at/persons/streit">Marc Streit</a> (<i
        >'Explainable AI'</i
      >); <a href="https://giorgialupi.com/about">Giorgia Lupi</a> (<i>'Data-driven storytelling'</i
      >); and <a href="https://tobias.isenberg.cc/">Tobias Isenberg</a> (<i>
        'Interactive data exploration in 2D & 3D'</i
      >). -->
    </p>

    <p>
      The VIZBI meeting {{ tense("brings", "brought") }} together a diverse community, including
      bioinformaticians, data scientists, computer scientists, and experimentalists, as well as
      medical illustrators, graphic designers, and graphic artists.
    </p>
    <!--<p>All participants will have the opportunity to present a <a href="Posters/">poster and lightning talk</a> describing their work.</p> -->
    <p>
      All participants {{ tense("will have", "had") }} the opportunity to present a poster
      <!-- <router-link to="/2025/Posters">poster</router-link> -->
      and lightning talk
      <!-- <router-link :to="{name: 'Posters', hash: '#Lightning'}">lightning talk</router-link>-->
      describing their work. To see examples from previous VIZBIs, please take a look at the
      <router-link to="/Posters/2021">VIZBI poster collection</router-link>.
    </p>
    <!-- <p>
      A post-workshop
      <router-link to="/2025/Showcase">Showcase event</router-link>
      on Friday evening
      {{ tense("will highlight", "highlighted") }} life science communication in film and
      interactive media. -->
    <!-- Further details about the Showcase will be announced
      shortly. -->
    <!-- </p> -->
    <p>
      VIZBI 2025 {{ tense("is set to be", "was") }} an exciting event that
      {{ tense("will help", "helped") }} raise the standard of data visualization in the life
      sciences.
      <span v-if="!past">We hope very much you can join us!</span>
      <span v-else>
        Highlights from the meeting are available on the VIZBI
        <router-link to="/blog">blog</router-link>,
        <router-link to="/posters/2025">posters</router-link>,
        <router-link to="/photos/2025">photos</router-link>, and
        <router-link to="/videos">videos</router-link> pages as well as on our
        <a href="https://twitter.com/vizbi">Twitter timeline</a>.</span
      >
    </p>
    <p v-if="!past">
      If you are interested in promoting your organization or professional society, please contact
      us to discuss sponsorship options.
      <!-- <a href="Sponsors/">sponsorship options</a>. -->
    </p>
    <p>
      To receive updates about VIZBI, we encourage you to join the
      <router-link to="/Mail">VIZBI mailing list</router-link>.
    </p>
    <KeyDates :items="keyDateItems" />
    <!-- <p><sup>*</sup>Registration for online participation remains open until the event begins.</p> -->
  </div>
</template>

<script setup>
// const log = (message) => console.log(message); // eslint-disable-line
import HeroVideo from "@/components/HeroVideo.vue";
import HeroImage from "@/components/HeroImage.vue";
import RegisterNowWrapper from "@/components/CallToAction/RegisterNowWrapper";
import KeyDates from "@/components/KeyDates";
import keyDateItems from "./keyDates.json";
import useTense from "@/composables/useTense";
const past = false;
const {tense} = useTense(past);

// const videoUrl =
// "https://player.vimeo.com/video/911933870?badge=0&autopause=0&player_id=0&app_id=58479";
// const playerContainer = ref(null);
// onMounted(async () => {
// Dynamically import the Vimeo Player API
// const {default: Player} = await import("@vimeo/player");
// const player = new Player(playerContainer.value.querySelector("iframe"));
// Set the volume to a normal level (e.g., 0.5) after the player is ready
//   player
//     .setVolume(0.5)
//     .then(() => {
//       // Volume successfully set
//     })
//     .catch((error) => {
//       // An error occurred setting the volume
//       console.error("Error setting volume:", error);
//     });
//   player.on("ended", () => {
//     // Option to reload the iframe to show the thumbnail
//     playerContainer.value.querySelector("iframe").src =
//       playerContainer.value.querySelector("iframe").src;
//   });
// });
</script>
