// Waits a DOM element to become available,  for a template 'ref'

import {ref} from "vue";

/**
 * A composable to wait for a ref to become available.
 * @param {Object} targetRef - The ref object to wait for.
 * @param {Number} timeout - Maximum time to wait in milliseconds (default: 5000).
 * @param {Number} interval - Polling interval in milliseconds (default: 100).
 * @returns {Promise} Resolves when the ref is available, or rejects on timeout.
 */
export function useWaitForRef(targetRef, timeout = 5000, interval = 100) {
  const isReady = ref(false);
  async function wait() {
    let elapsed = 0;
    while (!targetRef.value && elapsed < timeout) {
      await new Promise((resolve) => setTimeout(resolve, interval));
      elapsed += interval;
    }
    if (!targetRef.value) {
      throw new Error(`Timeout waiting for ref: ${targetRef}`);
    }
    isReady.value = true;
    return targetRef.value;
  }
  return {
    isReady,
    wait
  };
}
