<!-- <router-link v-if="isRegistrationPage" to="/2025/Registration/Form">
  Begin Registration
</router-link> -->

<template>
  <div>
    <router-link to="#pre-register"> Pre-register Now </router-link>
  </div>
</template>

<script setup>
const log = (message) => console.log(message); // eslint-disable-line
import {useRoute, useRouter} from "vue-router";
const [route, router] = [useRoute(), useRouter()];
const handleClick = async (event) => {
  log(`PreRegisterNow: handleClick()`);

  const fullPath = event.currentTarget.getAttribute("to"); // Access the `to` prop
  if (!fullPath) return log("PreRegisterNow: No `to` attribute found");
  const [path, hash] = fullPath.split("#"); // Split into path and hash

  log(`PreRegisterNow: [path, hash] = [${path}, ${hash}]`);
  log(`PreRegisterNow: route.path = ${route.path}`);

  if (route.path.includes(path)) return router.replace({hash: `#${hash}`}); // If already at path
  await router.push(path); // otherwise, first navigate to the path
  setTimeout(() => {
    router.replace({hash: `#${hash}`}); // then add the hash after short delay
  }, 3000); // Adjust delay as needed
};
</script>

<style scoped>
div {
  display: block; /* Ensures the div behaves as a block-level container */
  text-align: left; /* Centers the button horizontally */
}
a {
  white-space: nowrap;
  font-weight: bold;
  color: white;
  background-color: var(--brand-color);
  padding: 0.6rem 2.3rem;
  border-radius: 17px; /* Example value to increase rounded corners */
  cursor: pointer; /* Changes the cursor to indicate clickable items */
}
.narrow a {
  padding: 0.6rem 1.3rem;
}
/* Button-like styling for anchor tags */
a {
  text-align: center; /* Center the text inside the button */
  text-decoration: none; /* Removes underline from links */
  border: none; /* Removes border */
}

/* Hover effect */
:is(a:hover, a:focus) {
  color: white;
  background-color: var(--brand-hover-color); /* Darkens */
  text-decoration: none; /* Ensures text remains not underlined on hover */
  outline: none; /* Removes outline to keep the design clean */
  cursor: pointer !important;
}
/* Active (click) effect */
a:active {
  background-color: var(--brand-hover-color); /* darken */
  cursor: pointer !important;
}
.soldOut {
  background-color: red;
}
</style>
