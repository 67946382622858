<!-- Copyright: Seán I. O'Donoghue -->

<template>
  <nav :class="{selected: selected}">
    <PrimaryItem to="#stay-updated" class="mail" :selected="selected">Stay Updated</PrimaryItem>
  </nav>
  <!-- title="Click to join VIZBI mailing list" -->
  <!-- <button ref="mailButton" :class="{selected: selected}" >
    <router-link to="/Mail"> Join Mailing List </router-link>
  </button> -->
</template>

<script setup>
const log = (message) => console.log(message); // eslint-disable-line
import PrimaryItem from "@/components/Navigation/Primary/Item.vue";
import {ref} from "vue";
const selected = ref(false);
import {watch} from "vue";
import {useRoute} from "vue-router";
const route = useRoute();
watch(
  route,
  () => {
    log(`Mailing list mounted()`);
    if (route.name && route.name === "Mail") {
      // set button to autofocus
      // https://michaelnthiessen.com/set-focus-on-input-vue
      //this.$refs['mailButton'].focus();
      selected.value = true;
      log(`Current page is Mail view`);
    } else {
      // turn off autofocus
      selected.value = false;
      //this.$refs['mailButton'].blur();
    }
  },
  {immediate: true}
);
</script>

<style scoped>
nav {
  padding: 0;
  font-weight: 700;
  opacity: 0.65;
}
.mail {
  /* margin: -0.4rem 0 0 0; compensates for 0.25rem padding on PrimaryItem */
}
:is(nav.selected, nav:hover) {
  opacity: 1;
}
nav:hover {
  background-color: var(--focus-background-color);
  border-radius: 0.4rem;
}
nav .medium {
  font-size: 0.9rem;
}
nav .wide {
  font-size: 0.95rem;
}
nav .unlimited {
  font-size: 1rem;
}
:is(.medium, .wide, .unlimited) nav .mail {
  margin: -0.2rem 0 0 0; /*compensates for text-shadow (below) */
}
:deep(a) {
  /* for wide display */
  padding: 0 1em;
  color: var(--primary-navigation-color); /* override default link color */
  text-shadow: rgb(0, 0, 0) 0px 0px 6px;
}
.narrow .mail :deep(a) {
  color: var(--primary-navigation-color);
  justify-content: center;
  text-shadow: none;
}
:deep(a:hover) {
  color: var(--primary-navigation-color);
  background-color: none;
  border-radius: 0.4rem;
}
.lightMode.narrow .mail :deep(a) {
  color: var(--text-color-less-subtle);
  justify-content: center;
  text-shadow: none;
}
.narrow nav {
  display: inline-block;
  position: static;
  margin: 0 auto 0 auto;
  padding: 0.5rem 0;
  font-size: 1.5rem;
  opacity: 1;
  border-radius: 2rem;
  line-height: 2.3rem;
  width: 100%;
  text-align: center;
  background-color: var(--text-color-subtle);
}
.lightMode.narrow nav {
  background-color: var(--text-color-more-subtle);
}
.narrow nav:hover {
  /* background-color: var(--background-color); */
  opacity: 0.7;
}
.narrow nav.selected .mail :deep(a) {
  color: var(--primary-navigation-color) !important;
}
.narrow nav.selected {
  background-color: var(--brand-color);
}
.narrow nav.selected:hover {
  background-color: var(--brand-hover-color) !important;
}
</style>
