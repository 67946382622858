<!-- Copyright: Seán I. O'Donoghue -->
<!-- <template v-if="host !== 'vizbi.org'"> -->

<template>
  <h2>Cutting-Edge DataVis Advances. Inspiring Life Science Breakthroughs.</h2>
</template>

<script setup>
let host = window.location.host; //eslint-disable-line
</script>

<style scoped>
h2 {
  margin: 0;
  vertical-align: bottom;
  font-weight: 600;
  font-size: clamp(0.8rem, 3vw, 1.2rem);
  line-height: 140%;
  color: var(--subtitle-color);
  opacity: 100%;
}
/* .darkMode h2 {
  opacity: 100%;
}
.wide h2 {
  opacity: 50%;
}
.medium h2 {
  opacity: 70%;
} */
/* .narrow h2 {
  display: block;
  font-size: clamp(1rem, 5vw, 1.5rem);
  line-height: clamp(1.5rem, 5vw 2rem);
} */
</style>
