<template>
  <Calculator :events="events" :discounts="discounts" :abbreviations="abbreviations" />
  <RegisterNowWrapper v-if="history.registrationOpen" />
  <div v-else-if="history.preRegistrationOpen">
    <h4>Pre-registration</h4>
    <p>
      Please click the button below to pre-register. We will then notify you when Early Bird
      registration goes live for VIZBI 2025 and future events.
    </p>
    <PreRegisterNow style="font-size: 1.7rem; margin-top: 1.5rem; margin-bottom: 2.5rem" />
  </div>

  <h4>Discounted registration</h4>
  <p>
    The discounts listed below can be combined &mdash; by multiplying them together &mdash;
    resulting in savings of up to &sim;35% off the full registration fees.
  </p>

  <ul>
    <li>
      <b><i>Early Bird tickets</i></b> offer a 15% discount on registration. A limited quantity will
      be available until <b>{{ earlyBirdDeadline }}</b
      >.
    </li>
    <li>
      <b><i>ISCB members</i></b> receive a 15% discount on registration. For these and
      <a href="http://www.iscb.org/iscb-membership">other benefits</a>, consider joining the ISCB
      (<a href="http://iscb.org/">International Society for Computational Biology</a>).
    </li>
    <!--
  <li>
    <b><i>DVS discount:</i></b> General Members of the
    <a href="https://www.datavisualizationsociety.org/mission">Data Visualization Society</a> (DVS)
    receive a <b>15% discount</b> on registration, which can also be combined with the Early Bird discount. We encourage participants to consider joining the DVS to take advantage of these and
    <a href="https://www.datavisualizationsociety.org/membership">other benefits</a>. To take
    advantage of this discount, follow the instructions on the
    <a href="https://www.datavisualizationsociety.org/discount-codes">DVS partners discount page</a>
    (only accessible to general members).
  </li>
  -->
    <li>
      <b><i>Group registration</i></b> discounts of 10% are available by registering as a group of
      three or more.
    </li>
  </ul>

  <h4>What's included</h4>
  <ul>
    <li>
      <b><i>In-person registration</i></b> includes admission, event materials, coffee breaks, and
      lunch. For the VIZBI Workshop, the buffet dinner on Wednesday evening is also included.
    </li>
    <li>
      <b><i>Extra tickets</i></b> must be purchased to attend the Reception Buffet on Tuesday
      evening or the Gala Dinner on Thursday evening. We encourage participants to consider joining
      these outstanding networking opportunities.
    </li>
    <li>
      <b><i>Virtual registration</i></b> includes access to all talks (live-streamed and on-demand)
      and engage with the speakers by submitting questions. You also have the opportunity
      <router-link to="../Posters">present your work</router-link> and get feedback from the VIZBI
      community.
    </li>
  </ul>

  <h4>What's not included</h4>
  <p>Participants are expected to book and pay for their own accommodation and travel expenses.</p>

  <h4>Refund and cancellation policies</h4>
  <p>
    If you need to change or cancel your registration, send an email to
    <Email to="vizbi-registration" />. Cancellation requests received prior to March 25 will receive
    a full refund, minus a £50 processing fee. After this date, no refund of fees will be made.
  </p>
</template>

<script setup>
const log = (message) => console.log(message); // eslint-disable-line
import history from "@/assets/history.json";
import Calculator from "@/components/Registration/Calculator";
import events from "@/assets/years/2025/Registration/events.json";
import discounts from "@/assets/years/2025/Registration/discounts.json";
import abbreviations from "@/assets/years/2025/Registration/abbreviations.json";
import RegisterNowWrapper from "@/components/CallToAction/RegisterNowWrapper";
import PreRegisterNow from "@/components/CallToAction/PreRegisterNow";
import keyDateItems from "../keyDates.json";
import {DateTime} from "luxon";
let earlyBirdDeadline = keyDateItems["early-bird registration"].dates;
earlyBirdDeadline = DateTime.fromFormat(earlyBirdDeadline, "MMMM d").toFormat("cccc, MMMM d");
import Email from "@/components/Email.vue";
</script>

<style scoped>
h3 {
  color: var(--brand-color);
}
h4 {
  margin-bottom: 0px;
}
.submit:active {
  position: relative;
  top: 1px;
}
button {
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 15px;
}
button[disabled] {
  /* pointer-events: none; disabled cause it hides title */
  cursor: default;
  color: #929292;
  background-color: #f5f3f7;
  border: 1px solid rgba(60, 60, 60, 0.2);
}
ul {
  list-style-position: outside; /* Numbers outside the content box */
  margin-left: 1rem; /* Adjust margin for number alignment */
  padding-left: 0; /* No extra padding needed */
}
li {
  text-indent: 0; /* First line aligns naturally with numbers */
  line-height: 1.4;
  margin-bottom: 0.6rem;
}
</style>
